import { useAuth0 } from '@auth0/auth0-react'
import Cookie from 'js-cookie'
import { useEffect } from 'react'

export const Auth0LocalStorageProvider = ({ children }: any) => {
  const { user, getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    if (getAccessTokenSilently && user) {
      const existingToken = Cookie.get('token')
      if (!existingToken) {
        getAccessTokenSilently().then(token => {
          localStorage.setItem('token', token)
        })
      }
    }
  }, [getAccessTokenSilently, user])

  return children
}
