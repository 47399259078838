import React from 'react'
import { Provider } from 'react-redux'
import 'time-input-polyfill/auto'
import { Auth0Provider } from '@auth0/auth0-react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { navigate } from 'gatsby'
import { Auth0LocalStorageProvider } from './src/providers/Auth0LocalStorageProvider'
import { setupStore } from './src/store'

import '@fontsource/montserrat'
import 'react-toastify/dist/ReactToastify.css'

const store = setupStore()

const onRedirectCallback = appState => {
  // Use Gatsby's navigate method to replace the url
  navigate('/not-authenticated', { replace: false })
}

export const wrapRootElement = ({ element }) => {
  const domain = process.env.AUTH0_DOMAIN ?? 'dev-tritag.au.auth0.com'
  const clientId =
    process.env.AUTH0_CLIENT_ID ?? 'saEntGXNRpojMwsHXLvA9S2R8X1utSrh'
  const audience = `https://${domain}/api/v2/`

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience,
        redirect_uri: window.location.origin,
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={false}
    >
      <Provider store={store}>
        <Auth0LocalStorageProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            {element}
          </LocalizationProvider>
        </Auth0LocalStorageProvider>
      </Provider>
    </Auth0Provider>
  )
}
